<template>
  <ValidationObserver ref="observer" v-slot="{ handleSubmit }">
    <form @submit.prevent="handleSubmit(submit)">
      <md-card>
        <md-card-header
          class="md-card-header-icon"
          :class="getClass(headerColor)"
        >
          <div class="card-icon">
            <md-icon>perm_identity</md-icon>
          </div>
          <h4 class="title">
            Crie um novo usuário
          </h4>
          Para adicionar mais de uma Entidade ao Usuario, após cria-lo vá em
          Editar o Usuario e depois adicine uma nova Entidade a ele.<br /><br />
        </md-card-header>

        <md-card-content>
          <div class="md-layout">
            <div class="md-layout-item md-small-size-100 md-size-33">
              <ValidationProvider
                name="form.nomeEntidade"
                rules="required"
                v-slot="{ passed, failed, errors }"
              >
                <md-field
                  :class="[{ 'md-error': failed }, { 'md-valid': passed }]"
                >
                  <label for="entidadeSelect">Selecionar Entidade</label>
                  <md-select
                    v-if="!isFetching3"
                    v-model="entidadeSelect"
                    name="entidadeSelect"
                    @md-selected="selectEntidade"
                    :disabled="papel === 'ADMIN' ? null : 'disabled'"
                  >
                    <md-option
                      v-for="item in entidadeSelectOption"
                      :key="item.idEntidade"
                      :label="item.idEntidade"
                      :value="item.idEntidade"
                    >
                      {{ item.nomeEntidade }}
                    </md-option>
                  </md-select>
                </md-field>
                <span
                  id="error"
                  class="block text-red-600 text-xs absolute bottom-0 left-0"
                  v-if="errors[0]"
                  >{{ errors[0] }}
                </span>
              </ValidationProvider>
            </div>
          </div>
          <div class="md-layout">
            <div class="md-layout-item md-small-size-100 md-size-50">
              <ValidationProvider
                name="form.nomeusuario"
                :rules="{
                  regex: /^[a-zA-Z0-9\.\-\_\@]*$/,
                  min: 8,
                  max: 50,
                  required: true
                }"
                v-slot="{ passed, failed, errors }"
              >
                <md-field
                  :class="[{ 'md-error': failed }, { 'md-valid': passed }]"
                >
                  <label>Nome de Usuário</label>
                  <md-input v-model="form.nomeusuario" type="text"></md-input>
                </md-field>
                <span
                  id="error"
                  class="block text-red-600 text-xs absolute bottom-0 left-0"
                  v-if="errors[0]"
                  >{{ errors[0] }}
                </span>
              </ValidationProvider>
            </div>
            <div class="md-layout-item md-small-size-100 md-size-50">
              <ValidationProvider
                name="form.email"
                :rules="{
                  // eslint-disable-next-line prettier/prettier
                  email: true,
                  required: true
                }"
                v-slot="{ passed, failed, errors }"
              >
                <md-field
                  :class="[{ 'md-error': failed }, { 'md-valid': passed }]"
                >
                  <label>Email</label>
                  <md-input v-model="form.email" type="email"></md-input>
                </md-field>
                <span
                  id="error"
                  class="block text-red-600 text-xs absolute bottom-0 left-0"
                  v-if="errors[0]"
                  >{{ errors[0] }}
                </span>
              </ValidationProvider>
            </div>
          </div>
          <div class="md-layout">
            <div class="md-layout-item md-small-size-100 md-size-50">
              <ValidationProvider
                name="form.nomeCompleto"
                :rules="{
                  regex: /^[ a-zA-ZÀ-ÿ\u00f1\u00d1\']*$/,
                  min: 3,
                  max: 250,
                  required: true
                }"
                v-slot="{ passed, failed, errors }"
              >
                <md-field
                  :class="[{ 'md-error': failed }, { 'md-valid': passed }]"
                >
                  <label>Nome Completo</label>
                  <md-input
                    name="Nome Completo"
                    v-model="form.nomeCompleto"
                    type="text"
                    :maxlength="250"
                    class="uppercase"
                  ></md-input>
                  <!--<p id="error">{{ errors[0] }}</p>-->
                </md-field>
                <span
                  id="error"
                  class="block text-red-600 text-xs absolute bottom-0 left-0"
                  v-if="errors[0]"
                  >{{ errors[0] }}
                </span>
              </ValidationProvider>
            </div>
            <div class="md-layout-item md-small-size-100 md-size-50">
              <ValidationProvider
                name="form.cpfUsuario"
                :rules="{
                  regex: /^\d{3}\.\d{3}\.\d{3}\-\d{2}$/,
                  min: 14,
                  max: 14,
                  required: true
                }"
                v-slot="{ passed, failed, errors }"
              >
                <md-field
                  :class="[{ 'md-error': failed }, { 'md-valid': passed }]"
                >
                  <label>CPF</label>
                  <md-input
                    v-model="form.cpfUsuario"
                    type="text"
                    :maxlength="14"
                  ></md-input>
                </md-field>
                <span
                  id="error"
                  class="block text-red-600 text-xs absolute bottom-0 left-0"
                  v-if="errors[0]"
                  >{{ errors[0] }}
                </span>
              </ValidationProvider>
            </div>
          </div>
          <div class="md-layout">
            <div class="md-layout-item md-small-size-100 md-size-50">
              <ValidationProvider
                name="form.nomePapel"
                rules="required"
                v-slot="{ passed, failed, errors }"
              >
                <md-field
                  :class="[{ 'md-error': failed }, { 'md-valid': passed }]"
                >
                  <label for="papelSelect">Selecionar Papel</label>
                  <md-select
                    id="selectOptionField"
                    v-if="!isFetching2"
                    v-model="papelSelect"
                    name="papelSelect"
                    @md-selected="selectedPapel"
                  >
                    <md-option
                      v-for="item in papelSelectOption"
                      :key="item.idPapel"
                      :label="item.nomePapel"
                      :value="item.idPapel"
                    >
                      {{ item.nomePapel }}
                    </md-option>
                  </md-select>
                </md-field>
                <span
                  id="error"
                  class="block text-red-600 text-xs absolute bottom-0 left-0"
                  v-if="errors[0]"
                  >{{ errors[0] }}
                </span>
              </ValidationProvider>
              <ValidationProvider
                v-show="showContactList"
                name="form.idServidorOrPensionista"
                :rules="{
                  regex: /^[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12}$/,
                  min: 36,
                  required: showContactList
                }"
                v-slot="{ passed, failed, errors }"
              >
                <md-field
                  :class="[{ 'md-error': failed }, { 'md-valid': passed }]"
                >
                  <label>Selecione um Servidor ou Pensionista</label>
                  <md-input
                    ref="idServidorOrPensionistaRef"
                    v-model="servidorOrPensionistaSelectedState.id"
                    type="text"
                    autocomplete="off"
                  >
                  </md-input>
                </md-field>
                <span
                  id="error"
                  class="block text-red-600 text-xs absolute bottom-0 left-0"
                  v-if="errors[0]"
                  >Selecione um Servidor ou Pensionista Abaixo
                </span>
              </ValidationProvider>
              <list-contacts
                :key="componentKeyToRerender"
                v-show="showContactList"
                :searchText="search"
                :items_prop="tableData"
                :nomePapelSelecionado="nomePapelSelecionado"
              ></list-contacts>
            </div>
          </div>
          <div class="md-layout">
            <div class="md-layout-item md-small-size-100 md-size-50">
              <md-checkbox v-model="checkboxAutoGerarSenha"
                >Auto gerar a senha ?</md-checkbox
              >
              <md-checkbox v-model="form.enviarEmail"
                >Enviar por email ao usuário ?</md-checkbox
              >
              <ValidationProvider
                name="form.senha"
                :rules="{
                  regex: /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).*$/,
                  min: 8,
                  required: true,
                  confirmed: 'form.confirmacao'
                }"
                v-slot="{ passed, failed, errors }"
              >
                <md-field
                  :class="[{ 'md-error': failed }, { 'md-valid': passed }]"
                >
                  <label>Senha</label>
                  <md-input
                    ref="senha"
                    v-model="form.senha"
                    type="password"
                    autocomplete="off"
                  >
                  </md-input>
                </md-field>
                <span
                  id="error"
                  class="block text-red-600 text-xs absolute bottom-0 left-0"
                  v-if="errors[0]"
                  >{{ errors[0] }}
                </span>
              </ValidationProvider>

              <ValidationProvider
                vid="form.confirmacao"
                name="form.confirmacao"
                rules="min:8|required"
                v-slot="{ passed, failed, errors }"
              >
                <md-field
                  :class="[{ 'md-error': failed }, { 'md-valid': passed }]"
                >
                  <label>Confirmar Senha</label>
                  <md-input
                    ref="confirmacao"
                    v-model="form.confirmacao"
                    type="password"
                    autocomplete="off"
                  >
                  </md-input>
                </md-field>
                <span
                  id="error"
                  class="block text-red-600 text-xs absolute bottom-0 left-0"
                  v-if="errors[0]"
                  >{{ errors[0] }}
                </span>
              </ValidationProvider>
            </div>
            <div class="md-layout-item md-size-100 text-right">
              <md-button type="submit" class="md-raised md-success mt-4">
                CRIAR
              </md-button>
              <span>&nbsp;</span>
              <md-button @click="resetForm()" class="md-raised mt-4">
                LIMPAR
              </md-button>
            </div>
          </div>
        </md-card-content>
      </md-card>
    </form>
  </ValidationObserver>
</template>
<script>
import { extend, localize } from "vee-validate";
import pt_BR from "vee-validate/dist/locale/pt_BR.json";
import { store } from "../../../store";
import { mapState, mapActions, mapGetters } from "vuex";
import * as rules from "vee-validate/dist/rules";
import Vue from "vue";
//import zIndexElevator from "../js/zIndexElevator";
import ListContacts from "@/components/ListContacts";
//@click="zIndexElevatorCall()"
import Swal from "sweetalert2";
import { NOME_CAMPOS } from "@/pages/Dashboard/js/nomeCampos";
import { cpfMask } from "@/pages/Dashboard/js/cpfMask";

var pt_br = pt_BR;
var names = NOME_CAMPOS;
Vue.set(pt_br, "names", names);
/*for (let key in pt_br.messages) {
  if (key === "regex") {
    pt_br.messages.regex = "A senha requer 1 de cada dos seguintes itens: 1x letra maiúscula, 1x letra minúscula, 1x número, 1x caractere especial.";
      
  }
}*/
/*pt_br: {
  fields: {
    senha: {
      regex: "A senha requer 1 de cada dos seguintes itens: 1x letra maiúscula, 1x letra minúscula, 1x número, 1x caractere especial.";
    }
  }
}*/
for (let key in pt_br.messages) {
  if (key === "regex") {
    pt_br.messages.regex =
      "O campo Nome de Usuario só pode conter numeros, letras e caracteres especiais ' .   -   _   @ ' .";
  }
}
var fields = {
  "form.cpfUsuario": {
    regex: "O campo CPF é inválido !"
  },
  "form.senha": {
    regex:
      "A senha requer 1 de cada dos seguintes itens: 1x letra maiúscula, 1x letra minúscula, 1x número, 1x caractere especial."
  },
  "form.idServidorOrPensionista": {
    regex: "id com formato inválido."
  },
  "form.confirmacao": {
    regex:
      "A senha requer 1 de cada dos seguintes itens: 1x letra maiúscula, 1x letra minúscula, 1x número, 1x caractere especial."
  },
  "form.nomeCompleto": {
    regex:
      "O campo Nome Completo não pode ter numeros, nem caracteres especiais."
  }
};

for (let key in pt_br.messages) {
  extend(key, rules[key]);
}
Vue.set(pt_br, "fields", fields);
localize("pt_BR", pt_br);

export default {
  props: {
    headerColor: {
      type: String,
      default: "green"
    }
  },
  components: { ListContacts },
  computed: {
    ...mapState("auth", ["user_profile"]),
    ...mapGetters("servidor_pensionista", [
      "getServidorOrPensionistaSelectedState"
    ]),
    servidorOrPensionistaSelected: getters =>
      getters.getServidorOrPensionistaSelectedState,
    servidorOrPensionistaSelectedState: {
      get() {
        return this.servidorOrPensionistaSelected;
      },
      set(newServidorOrPensionista) {
        return newServidorOrPensionista;
      }
    },
    user_profile_state: state => state.user_profile
  },
  data() {
    return {
      nomePapelSelecionado: "",
      componentKeyToRerender: 0,
      showContactList: false,
      search: "",
      isFetching: true,
      isFetching2: true,
      isFetching3: true,
      mensagem: "",
      senhaAutoGerada: "",
      size: "12",
      characters: "a-z,A-Z,0-9,#",
      checkboxAutoGerarSenha: false,
      form: {
        nomeusuario: null,
        idEntidade: null,
        email: null,
        nomeCompleto: null,
        cpfUsuario: null,
        idPapel: null,
        idServidorOrPensionista: null,
        papelAssociacao: null,
        senha: null,
        confirmacao: null,
        enviarEmail: false,
        emailConfirmado: "1"
      },
      papel: "",
      papelSelectOption: [],
      papelSelect: "",
      entidadeSelect: "",
      entidadeSelectOption: [],
      tableData: []
    };
  },
  beforeMount() {
    this.getPapel();
    this.getPapelAll();
    this.getEntidadeAll();
  },
  mounted() {
    this.ActionSetServidorOrPensionistaSelected({});
  },
  methods: {
    ...mapActions("user", ["ActionPOSTUser"]),
    ...mapActions("papel", ["ActionGetPapelAll"]),
    ...mapActions("servidor_pensionista", [
      "ActionGetServidorOrPensionistaToAssingmentFromEntidadeAll",
      "ActionSetServidorOrPensionistaSelected"
    ]),
    ...mapActions("entidade", ["ActionGetEntidadeAll"]),
    getClass: function(headerColor) {
      return "md-card-header-" + headerColor + "";
    },
    /*zIndexElevatorCall: function() {
      zIndexElevator();
    },*/
    getPapel() {
      this.papel = this.user_profile_state.papel;
    },
    async getPapelAll() {
      try {
        let papel = this.papel;
        await this.ActionGetPapelAll({ papel }).then(response => {
          let papelFilter = response.body;
          papelFilter.sort((a, b) => {
            if (a.nomePapel < b.nomePapel) return -1;
            if (a.nomePapel > b.nomePapel) return 1;
            return 0;
          });
          if (this.papel === "ADMIN") {
            this.papelSelectOption = papelFilter;
          } else {
            let index = -1;
            let filteredResult = papelFilter.find(function(item, i) {
              if (item.nomePapel === "ADMIN") {
                index = i;
                return item;
              }
            });
            papelFilter.splice(index, 1);
            this.papelSelectOption = papelFilter;
          }
          this.isFetching2 = false;
        });
      } catch (err) {
        console.log(err);
        this.handleError(err);
      }
    },
    async getEntidadeAll() {
      try {
        let papel = this.papel;
        let idEntidade = this.user_profile_state.idEntidade;
        await this.ActionGetEntidadeAll({ papel, idEntidade }).then(
          response => {
            let result = response.body;
            if (this.papel === "ADMIN") {
              result.sort((a, b) => {
                if (a.nomeEntidade < b.nomeEntidade) return -1;
                if (a.nomeEntidade > b.nomeEntidade) return 1;
                return 0;
              });
              this.entidadeSelectOption = result;
            } else {
              this.entidadeSelectOption = [];
              this.entidadeSelectOption.push({
                idEntidade: result.idEntidade,
                nomeEntidade: result.nomeEntidade
              });
              this.form.idEntidade = this.user_profile_state.idEntidade;
              this.entidadeSelect = this.user_profile_state.idEntidade;
              this.entidadeSelectOption = this.entidadeSelectOption;
            }
            this.isFetching3 = false;
            return this.entidadeSelectOption;
          }
        );
      } catch (err) {
        console.log(err);
        this.handleError(err);
      }
    },
    gerarSenha() {
      let charactersArray = this.characters.split(",");
      let CharacterSet = "";
      let password = "";

      if (charactersArray.indexOf("a-z") >= 0) {
        CharacterSet += "abcdefghijklmnopqrstuvwxyz";
      }
      if (charactersArray.indexOf("A-Z") >= 0) {
        CharacterSet += "ABCDEFGHIJKLMNOPQRSTUVWXYZ";
      }
      if (charactersArray.indexOf("0-9") >= 0) {
        CharacterSet += "0123456789";
      }
      if (charactersArray.indexOf("#") >= 0) {
        CharacterSet += "#?!@$%^&*-";
      }
      let passed = true;
      while (passed) {
        for (let i = 0; i < this.size; i++) {
          password += CharacterSet.charAt(
            Math.floor(Math.random() * CharacterSet.length)
          );
        }

        let regexCheck = password.match(
          /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).*$/
        );
        if (regexCheck != null && regexCheck.length === 1) {
          passed = false;
        } else {
          password = "";
        }
      }
      return (this.senhaAutoGerada = password);
    },
    async submit() {
      try {
        this.form.idServidorOrPensionista = this.servidorOrPensionistaSelectedState.id;
        let papel = this.papel;
        let form = this.form;
        let response = await this.ActionPOSTUser({
          papel,
          form
        });
        if (response.status === 200) {
          this.mensagem = response.body.mensagem;
          this.resetForm();
          this.ActionSetServidorOrPensionistaSelected({});
          if (this.mensagem.includes("não")) {
            Swal.fire({
              //title: "Sucesso",
              icon: "warning",
              text: this.mensagem,
              showCancelButton: false,
              customClass: {
                confirmButton: "md-button md-success btn-fill"
              },
              buttonsStyling: false,
              confirmButtonText: "Ok"
            });
          } else {
            Swal.fire({
              //title: "Sucesso",
              icon: "success",
              text: this.mensagem,
              showCancelButton: false,
              customClass: {
                confirmButton: "md-button md-success btn-fill"
              },
              buttonsStyling: false,
              confirmButtonText: "Ok"
            }).then(result => {
              if (result.value) {
                return window._Vue.$router
                  .push({ name: "Usuarios" })
                  .catch(err => {});
              }
            });
          }
        }
      } catch (err) {
        this.handleError(err);
      }
    },
    handleError(err) {
      console.log(err);
      if (
        err === 400 ||
        err === 500 ||
        err === 404 ||
        err.status === 400 ||
        err.status === 500 ||
        err.status === 404
      ) {
        this.mensagem = "Aplicação temporariamente indisponível.";
      } else if (err.status === 401 || err.data === "Unauthorized") {
        this.mensagem = "Operação não Autorizada";
      } else {
        if (err.body) {
          this.mensagem = err.body.mensagem;
        } else if (err.status === 0) {
          this.mensagem =
            "Verifique sua conexão com a internet ou aguarde restabelecer conexões.";
        } else if (err.status === 409) {
          this.mensagem = err.body.mensagem;
        } else {
          this.mensagem =
            "Operação temporariamente indisponível tente mais tarde.";
        }
      }
      Swal.fire({
        title: "Erro!",
        icon: "error",
        text: this.mensagem,
        customClass: {
          confirmButton: "md-button md-danger btn-fill"
        },
        buttonsStyling: false
      });
    },
    resetForm() {
      this.form.nomeusuario = null;
      this.form.idEntidade = "";
      this.form.email = "";
      this.form.nomeCompleto = "";
      this.form.cpfUsuario = "";
      this.form.idPapel = "";
      this.form.senha = "";
      this.form.confirmacao = "";
      this.form.idServidorOrPensionista = "";
      this.form.enviarEmail = false;
      this.servidorOrPensionistaSelectedState = undefined;
      this.papelSelect = undefined;
      this.entidadeSelect = undefined;

      this.showContactList = false;
      this.checkboxAutoGerarSenha = false;

      requestAnimationFrame(() => {
        this.$refs.observer.reset();
      });
    },
    forceRerender() {
      this.componentKeyToRerender += 1;
    },
    selectEntidade(item) {
      this.form.idEntidade = item;
      if (
        // eslint-disable-next-line prettier/prettier
        typeof this.nomePapelSelecionado !== 'undefined' &&
        this.nomePapelSelecionado !== "" &&
        this.nomePapelSelecionado !== null
      ) {
        this.selectedPapel(this.form.idPapel);
      }
    },
    async selectedPapel(item) {
      if (
        // eslint-disable-next-line prettier/prettier
        typeof item !== 'undefined' &&
        item !== "" &&
        item !== null
      ) {
        this.form.idPapel = item;
        let id = item;
        let index = -1;
        let filteredResult = this.papelSelectOption.find(function(item, i) {
          if (item.idPapel === id) {
            index = i;
            return item;
          }
        });
        this.form.papelAssociacao = filteredResult.nomePapel;
        if (
          filteredResult.nomePapel === "PENSIONISTA" ||
          filteredResult.nomePapel === "SERVIDOR"
        ) {
          if (this.form.idEntidade != null) {
            let idEntidade = this.form.idEntidade;
            let nomePapel = filteredResult.nomePapel;
            this.getServidorOrPensionistaToAssingmentFromEntidade(
              idEntidade,
              nomePapel
            );
            await this.delay(2000);
            this.forceRerender();
            this.showContactList = true;
          } else {
            this.form.idPapel = null;
            this.form.papelAssociacao = null;
            this.papelSelect = undefined;
            Swal.fire({
              icon: "warning",
              title: "Selecione uma Entidade antes!",
              text:
                "Uma entidade deve ser escolhida antes para filtra Servidores ou Pensionistas",
              customClass: {
                confirmButton: "md-button md-info btn-fill"
              },
              buttonsStyling: false
            });
          }
        } else {
          this.showContactList = false;
        }
      }
    },
    delay(ms) {
      return new Promise(resolve => setTimeout(resolve, ms));
    },
    async getServidorOrPensionistaToAssingmentFromEntidade(
      idEntidade,
      nomePapel
    ) {
      try {
        let papel = this.papel;
        let body = { idEntidade: idEntidade, nomePapel: nomePapel };
        let response = await this.ActionGetServidorOrPensionistaToAssingmentFromEntidadeAll(
          { papel, body }
        );
        //console.log(response);
        if (response.status === 200) {
          let results = response.body;
          //console.log(results);
          if (!results.mensagem) {
            this.nomePapelSelecionado = nomePapel;
            this.tableData = results;
          } else {
            if (results.mensagem.includes("não encontrados")) {
              this.nomePapelSelecionado = nomePapel;
              this.tableData = [];
            }
          }
        }
      } catch (err) {
        this.handleError(err);
      }
    }
  },
  watch: {
    checkboxAutoGerarSenha(value) {
      if (value) {
        this.senhaAutoGerada = this.gerarSenha();
        this.form.senha = this.senhaAutoGerada;
        this.form.confirmacao = this.senhaAutoGerada;
      } else {
        this.form.senha = this.form.confirmacao = "";
      }
    },
    "form.cpfUsuario": function(val, oldVal) {
      let cpf = cpfMask(val);
      this.form.cpfUsuario = cpf;
    }
  }
};
</script>

<style lang="css" scoped>
::v-deep .paginated-table .md-table-head-label {
  font-size: 0.875rem;
  font-weight: 400;
}

.uppercase {
  text-transform: uppercase;
}

.md-table-cell {
  font-size: 12px;
}
#error {
  color: red;
  font-size: 0.75rem;
  margin-top: 0;
  transition: all 0.2s ease-in-out;
}

.md-card .md-card-actions {
  border: none;
  margin-left: 20px;
  margin-right: 20px;
}
.space-tool-bar {
  width: 50px;
  height: 50px;
}

.md-button.md-info.md-simple,
.md-button.md-info.md-simple:hover,
.md-button.md-info.md-simple:focus,
.md-button.md-info.md-simple:active,
.md-button.md-info.md-simple.active,
.md-button.md-info.md-simple:active:focus,
.md-button.md-info.md-simple:active:hover,
.md-button.md-info.md-simple.active:focus,
.md-button.md-info.md-simple.active:hover {
  margin-right: 5px;
  margin-left: 5px;
}

.md-button.md-success.md-simple,
.md-button.md-success.md-simple:hover,
.md-button.md-success.md-simple:focus,
.md-button.md-success.md-simple:active,
.md-button.md-success.md-simple.active,
.md-button.md-success.md-simple:active:focus,
.md-button.md-success.md-simple:active:hover,
.md-button.md-success.md-simple.active:focus,
.md-button.md-success.md-simple.active:hover {
  margin-right: 5px;
  margin-left: 5px;
}
.paginated-table .md-table-cell-container .md-button .md-button-content i {
  font-size: 24px !important;
}

::v-deep .md-checkbox .md-checkbox-label {
  color: #000;
}
</style>
