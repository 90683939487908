var render = function render(){var _vm=this,_c=_vm._self._c;return _c('ValidationObserver',{ref:"observer",scopedSlots:_vm._u([{key:"default",fn:function({ handleSubmit }){return [_c('form',{on:{"submit":function($event){$event.preventDefault();return handleSubmit(_vm.submit)}}},[_c('md-card',[_c('md-card-header',{staticClass:"md-card-header-icon",class:_vm.getClass(_vm.headerColor)},[_c('div',{staticClass:"card-icon"},[_c('md-icon',[_vm._v("perm_identity")])],1),_c('h4',{staticClass:"title"},[_vm._v(" Crie um novo usuário ")]),_vm._v(" Para adicionar mais de uma Entidade ao Usuario, após cria-lo vá em Editar o Usuario e depois adicine uma nova Entidade a ele."),_c('br'),_c('br')]),_c('md-card-content',[_c('div',{staticClass:"md-layout"},[_c('div',{staticClass:"md-layout-item md-small-size-100 md-size-33"},[_c('ValidationProvider',{attrs:{"name":"form.nomeEntidade","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function({ passed, failed, errors }){return [_c('md-field',{class:[{ 'md-error': failed }, { 'md-valid': passed }]},[_c('label',{attrs:{"for":"entidadeSelect"}},[_vm._v("Selecionar Entidade")]),(!_vm.isFetching3)?_c('md-select',{attrs:{"name":"entidadeSelect","disabled":_vm.papel === 'ADMIN' ? null : 'disabled'},on:{"md-selected":_vm.selectEntidade},model:{value:(_vm.entidadeSelect),callback:function ($$v) {_vm.entidadeSelect=$$v},expression:"entidadeSelect"}},_vm._l((_vm.entidadeSelectOption),function(item){return _c('md-option',{key:item.idEntidade,attrs:{"label":item.idEntidade,"value":item.idEntidade}},[_vm._v(" "+_vm._s(item.nomeEntidade)+" ")])}),1):_vm._e()],1),(errors[0])?_c('span',{staticClass:"block text-red-600 text-xs absolute bottom-0 left-0",attrs:{"id":"error"}},[_vm._v(_vm._s(errors[0])+" ")]):_vm._e()]}}],null,true)})],1)]),_c('div',{staticClass:"md-layout"},[_c('div',{staticClass:"md-layout-item md-small-size-100 md-size-50"},[_c('ValidationProvider',{attrs:{"name":"form.nomeusuario","rules":{
                regex: /^[a-zA-Z0-9\.\-\_\@]*$/,
                min: 8,
                max: 50,
                required: true
              }},scopedSlots:_vm._u([{key:"default",fn:function({ passed, failed, errors }){return [_c('md-field',{class:[{ 'md-error': failed }, { 'md-valid': passed }]},[_c('label',[_vm._v("Nome de Usuário")]),_c('md-input',{attrs:{"type":"text"},model:{value:(_vm.form.nomeusuario),callback:function ($$v) {_vm.$set(_vm.form, "nomeusuario", $$v)},expression:"form.nomeusuario"}})],1),(errors[0])?_c('span',{staticClass:"block text-red-600 text-xs absolute bottom-0 left-0",attrs:{"id":"error"}},[_vm._v(_vm._s(errors[0])+" ")]):_vm._e()]}}],null,true)})],1),_c('div',{staticClass:"md-layout-item md-small-size-100 md-size-50"},[_c('ValidationProvider',{attrs:{"name":"form.email","rules":{
                // eslint-disable-next-line prettier/prettier
                email: true,
                required: true
              }},scopedSlots:_vm._u([{key:"default",fn:function({ passed, failed, errors }){return [_c('md-field',{class:[{ 'md-error': failed }, { 'md-valid': passed }]},[_c('label',[_vm._v("Email")]),_c('md-input',{attrs:{"type":"email"},model:{value:(_vm.form.email),callback:function ($$v) {_vm.$set(_vm.form, "email", $$v)},expression:"form.email"}})],1),(errors[0])?_c('span',{staticClass:"block text-red-600 text-xs absolute bottom-0 left-0",attrs:{"id":"error"}},[_vm._v(_vm._s(errors[0])+" ")]):_vm._e()]}}],null,true)})],1)]),_c('div',{staticClass:"md-layout"},[_c('div',{staticClass:"md-layout-item md-small-size-100 md-size-50"},[_c('ValidationProvider',{attrs:{"name":"form.nomeCompleto","rules":{
                regex: /^[ a-zA-ZÀ-ÿ\u00f1\u00d1\']*$/,
                min: 3,
                max: 250,
                required: true
              }},scopedSlots:_vm._u([{key:"default",fn:function({ passed, failed, errors }){return [_c('md-field',{class:[{ 'md-error': failed }, { 'md-valid': passed }]},[_c('label',[_vm._v("Nome Completo")]),_c('md-input',{staticClass:"uppercase",attrs:{"name":"Nome Completo","type":"text","maxlength":250},model:{value:(_vm.form.nomeCompleto),callback:function ($$v) {_vm.$set(_vm.form, "nomeCompleto", $$v)},expression:"form.nomeCompleto"}})],1),(errors[0])?_c('span',{staticClass:"block text-red-600 text-xs absolute bottom-0 left-0",attrs:{"id":"error"}},[_vm._v(_vm._s(errors[0])+" ")]):_vm._e()]}}],null,true)})],1),_c('div',{staticClass:"md-layout-item md-small-size-100 md-size-50"},[_c('ValidationProvider',{attrs:{"name":"form.cpfUsuario","rules":{
                regex: /^\d{3}\.\d{3}\.\d{3}\-\d{2}$/,
                min: 14,
                max: 14,
                required: true
              }},scopedSlots:_vm._u([{key:"default",fn:function({ passed, failed, errors }){return [_c('md-field',{class:[{ 'md-error': failed }, { 'md-valid': passed }]},[_c('label',[_vm._v("CPF")]),_c('md-input',{attrs:{"type":"text","maxlength":14},model:{value:(_vm.form.cpfUsuario),callback:function ($$v) {_vm.$set(_vm.form, "cpfUsuario", $$v)},expression:"form.cpfUsuario"}})],1),(errors[0])?_c('span',{staticClass:"block text-red-600 text-xs absolute bottom-0 left-0",attrs:{"id":"error"}},[_vm._v(_vm._s(errors[0])+" ")]):_vm._e()]}}],null,true)})],1)]),_c('div',{staticClass:"md-layout"},[_c('div',{staticClass:"md-layout-item md-small-size-100 md-size-50"},[_c('ValidationProvider',{attrs:{"name":"form.nomePapel","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function({ passed, failed, errors }){return [_c('md-field',{class:[{ 'md-error': failed }, { 'md-valid': passed }]},[_c('label',{attrs:{"for":"papelSelect"}},[_vm._v("Selecionar Papel")]),(!_vm.isFetching2)?_c('md-select',{attrs:{"id":"selectOptionField","name":"papelSelect"},on:{"md-selected":_vm.selectedPapel},model:{value:(_vm.papelSelect),callback:function ($$v) {_vm.papelSelect=$$v},expression:"papelSelect"}},_vm._l((_vm.papelSelectOption),function(item){return _c('md-option',{key:item.idPapel,attrs:{"label":item.nomePapel,"value":item.idPapel}},[_vm._v(" "+_vm._s(item.nomePapel)+" ")])}),1):_vm._e()],1),(errors[0])?_c('span',{staticClass:"block text-red-600 text-xs absolute bottom-0 left-0",attrs:{"id":"error"}},[_vm._v(_vm._s(errors[0])+" ")]):_vm._e()]}}],null,true)}),_c('ValidationProvider',{directives:[{name:"show",rawName:"v-show",value:(_vm.showContactList),expression:"showContactList"}],attrs:{"name":"form.idServidorOrPensionista","rules":{
                regex: /^[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12}$/,
                min: 36,
                required: _vm.showContactList
              }},scopedSlots:_vm._u([{key:"default",fn:function({ passed, failed, errors }){return [_c('md-field',{class:[{ 'md-error': failed }, { 'md-valid': passed }]},[_c('label',[_vm._v("Selecione um Servidor ou Pensionista")]),_c('md-input',{ref:"idServidorOrPensionistaRef",attrs:{"type":"text","autocomplete":"off"},model:{value:(_vm.servidorOrPensionistaSelectedState.id),callback:function ($$v) {_vm.$set(_vm.servidorOrPensionistaSelectedState, "id", $$v)},expression:"servidorOrPensionistaSelectedState.id"}})],1),(errors[0])?_c('span',{staticClass:"block text-red-600 text-xs absolute bottom-0 left-0",attrs:{"id":"error"}},[_vm._v("Selecione um Servidor ou Pensionista Abaixo ")]):_vm._e()]}}],null,true)}),_c('list-contacts',{directives:[{name:"show",rawName:"v-show",value:(_vm.showContactList),expression:"showContactList"}],key:_vm.componentKeyToRerender,attrs:{"searchText":_vm.search,"items_prop":_vm.tableData,"nomePapelSelecionado":_vm.nomePapelSelecionado}})],1)]),_c('div',{staticClass:"md-layout"},[_c('div',{staticClass:"md-layout-item md-small-size-100 md-size-50"},[_c('md-checkbox',{model:{value:(_vm.checkboxAutoGerarSenha),callback:function ($$v) {_vm.checkboxAutoGerarSenha=$$v},expression:"checkboxAutoGerarSenha"}},[_vm._v("Auto gerar a senha ?")]),_c('md-checkbox',{model:{value:(_vm.form.enviarEmail),callback:function ($$v) {_vm.$set(_vm.form, "enviarEmail", $$v)},expression:"form.enviarEmail"}},[_vm._v("Enviar por email ao usuário ?")]),_c('ValidationProvider',{attrs:{"name":"form.senha","rules":{
                regex: /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).*$/,
                min: 8,
                required: true,
                confirmed: 'form.confirmacao'
              }},scopedSlots:_vm._u([{key:"default",fn:function({ passed, failed, errors }){return [_c('md-field',{class:[{ 'md-error': failed }, { 'md-valid': passed }]},[_c('label',[_vm._v("Senha")]),_c('md-input',{ref:"senha",attrs:{"type":"password","autocomplete":"off"},model:{value:(_vm.form.senha),callback:function ($$v) {_vm.$set(_vm.form, "senha", $$v)},expression:"form.senha"}})],1),(errors[0])?_c('span',{staticClass:"block text-red-600 text-xs absolute bottom-0 left-0",attrs:{"id":"error"}},[_vm._v(_vm._s(errors[0])+" ")]):_vm._e()]}}],null,true)}),_c('ValidationProvider',{attrs:{"vid":"form.confirmacao","name":"form.confirmacao","rules":"min:8|required"},scopedSlots:_vm._u([{key:"default",fn:function({ passed, failed, errors }){return [_c('md-field',{class:[{ 'md-error': failed }, { 'md-valid': passed }]},[_c('label',[_vm._v("Confirmar Senha")]),_c('md-input',{ref:"confirmacao",attrs:{"type":"password","autocomplete":"off"},model:{value:(_vm.form.confirmacao),callback:function ($$v) {_vm.$set(_vm.form, "confirmacao", $$v)},expression:"form.confirmacao"}})],1),(errors[0])?_c('span',{staticClass:"block text-red-600 text-xs absolute bottom-0 left-0",attrs:{"id":"error"}},[_vm._v(_vm._s(errors[0])+" ")]):_vm._e()]}}],null,true)})],1),_c('div',{staticClass:"md-layout-item md-size-100 text-right"},[_c('md-button',{staticClass:"md-raised md-success mt-4",attrs:{"type":"submit"}},[_vm._v(" CRIAR ")]),_c('span',[_vm._v(" ")]),_c('md-button',{staticClass:"md-raised mt-4",on:{"click":function($event){return _vm.resetForm()}}},[_vm._v(" LIMPAR ")])],1)])])],1)],1)]}}])})
}
var staticRenderFns = []

export { render, staticRenderFns }